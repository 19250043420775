















































































































































































import { ShopInformation } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import informationService from "@/service/shopInformationService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinValidator from "@/components/mixins/information/MixinInformationEditDialogValidator.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import moment from "moment";
import shopInformationService from "@/service/shopInformationService";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class InformationEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly informationForm!: HTMLFormElement;
  hours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: ShopInformation = informationService.defaultShopInformation;
  publicationDayMenu = false;
  publicationDay = "";
  publicationTime = "";
  publicationEndDayMenu = false;
  publicationEndDay = "";
  publicationEndTime = "";
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 公開開始日の入力値から日時を取得
   */
  get publicationDate(): string {
    return moment(this.publicationDay + " " + this.publicationTime).format();
  }
  /**
   * 公開開始日の入力値から日時を取得
   */
  get publicationEndDate(): string {
    return moment(
      this.publicationEndDay + " " + this.publicationEndTime
    ).format();
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.item.createdAt
      ? moment(this.item.createdAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.item.updatedAt
      ? moment(this.item.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }

  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: ShopInformation): Promise<void> {
    this.item = informationService.defaultShopInformation;
    if (item.guid) {
      const result = await informationService.getShopInformation(item.guid);
      if (result) {
        this.item = result;

        //フォームに表示するためにフォーマット変換
        this.publicationDay = moment(this.item.publicationDate).format(
          "YYYY-MM-DD"
        );
        this.publicationTime = moment(this.item.publicationDate).format(
          "HH:00"
        );
        this.publicationEndDay = moment(this.item.publicationEndDate).format(
          "YYYY-MM-DD"
        );
        this.publicationEndTime = moment(this.item.publicationEndDate).format(
          "HH:00"
        );
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: ShopInformation): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      this.item.publicationDate = this.publicationDate;
      this.item.publicationEndDate = this.publicationEndDate;
      if (this.item.guid) {
        //update
        shopInformationService
          .updateShopInformation(this.item)
          .then((result) => {
            this.isProgressing = true;
            this.notifyUpdateSuccess(result as ShopInformation);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("お知らせの更新ができませんでした.");
          });
      } else {
        //create
        shopInformationService
          .createShopInformation(this.item)
          .then((result) => {
            this.isProgressing = true;
            this.notifyCreateSuccess(result as ShopInformation);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("お知らせの登録ができませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: ShopInformation): ShopInformation {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: ShopInformation): ShopInformation {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}


















































import { Component, Ref, Mixins } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import informationService from "@/service/shopInformationService";
import InformationEditDialog from "@/components/organisms/information/InformationEditDialog.vue";
import InformationDeleteDialog from "@/components/organisms/information/InformationDeleteDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import { ShopInformation } from "@/graphql/client";

@Component({ components: { InformationEditDialog, InformationDeleteDialog } })
export default class InformationList extends Mixins(MixinFormatter) {
  @Ref() readonly editDialog!: InformationEditDialog;
  @Ref() readonly deleteDialog!: InformationDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: ShopInformation[] = [];
  headers = [
    {
      text: "公開開始日",
      value: "publicationDate",
      width: "15%",
      formatter: this.formatDateTime,
    },
    {
      text: "公開終了",
      value: "publicationEndDate",
      width: "15%",
      formatter: this.formatDateTime,
    },
    {
      text: "タイトル",
      value: "title",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    informationService.allShopInformations().then((items) => {
      this.items = items;
    this.loadingDataGrid = false;
    });
  }
  /**
   * お知らせ編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(informationService.defaultShopInformation);
  }

  /**
   * お知らせ編集ダイアログを表示します.
   */
  public openEditDialog(item: ShopInformation): void {
    this.editDialog.open(item);
  }

  /**
   * お知らせ編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: ShopInformation): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("お知らせを更新しました！");
  }

  /**
   * お知らせ編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: ShopInformation): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("お知らせを登録しました！");
  }

  /**
   * お知らせ削除ダイアログを表示します.
   */
  public openDeleteDialog(item: ShopInformation): void {
    this.deleteDialog.open(item);
  }

  /**
   * お知らせ削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: ShopInformation): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("お知らせを削除しました！");
  }
}
